import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

function Header() {
  //for active class
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  return (
    <div className='header'>
      <Container>
        <Navbar expand='lg'>
          <Navbar.Brand>
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
          </Navbar.Brand>
          {/* <Button variant="contained" color="primary" className="def_btn btn_head">Subscribe</Button> */}
        </Navbar>
      </Container>
    </div>
  );
}

export default Header;

import React, { useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  CircularProgress,
} from '@material-ui/core';
// import emailjs from 'emailjs-com';
// import DatePicker from 'react-datepicker';

import countryList from 'react-select-country-list';
// import Select from "react-select";

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },

  input: {
    display: 'none',
  },

  circularProgress: {
    color: 'white',
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
});

function Home(props) {
  const { classes } = props;
  const [startDate, setStartDate] = useState();
  const [country, setCountry] = useState('');
  const options = useMemo(() => countryList().getData(), []);
  const [loading, setLoading] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#ffffff',
      width: '255px',
      // match with the menu
      borderRadius: state.isFocused ? '45px' : '45px',
      // Overwrittes the different states of border
      borderColor: state.isFocused ? 'white' : 'white',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
  };

  // const changeHandler = (country) => {
  //   setCountry(country);
  // };
  const changeHandler = (event) => {
    setCountry(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      /*       country: '', */
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);

      try {
        window.Email.send({
          Host: 'nekotoshi.io',
          Username: 'welcome@nekotoshi.io',
          Password: '3EJrLtWC6jhxWtq',
          To: 'welcome@nekotoshi.io',
          From: 'welcome@nekotoshi.io',
          Subject: 'New Subscription',
          Body:
            values.email +
            ' is a new subscriber<br />' +
            'DOB:' +
            startDate +
            ' <br />Country:' +
            country,
        }).then((message) => {
          setLoading(false);
          alert('Submitted Successful');
        });

        window.Email.send({
          Host: 'nekotoshi.io',
          Username: 'welcome@nekotoshi.io',
          Password: '3EJrLtWC6jhxWtq',
          To: values.email,
          From: 'welcome@nekotoshi.io',
          Subject: 'Welcome to Nekotoshi',
          Body: `<p>Welcome to Nekotoshi, your new home for amazing anime cat girls! Get ready to dive into a world of fun, sexy and unique anime, and connect with the community of fellow fans! As a subscriber, you will be the first to receive news and updates. Thanks for joining!</p>
          <p>&nbsp;</p>
          <p><img src="https://nekotoshi.io/Nekotoshi_Email_Welcome.jpg" width="100%" /></p>`,
        });
      } catch (error) {
        console.log(error);
        alert('Error');
      }
    },
  });
  return (
    <div className='hm_bg'>
      <Container>
        <Row>
          <Col md={12}>
            <Box className='main_box'>
              <Typography variant='h4' component='h3'>
                Love Neko Anime?
              </Typography>
              <Typography component='p'>
                Nekotoshi is your source for neko NFTs that are unique,
                beautiful, and created by and for anime fans. Subscribe below
                and stay up to date with the latest news. Neko are coming!
              </Typography>
              <label className='csmcntrlgrp_lbl'>Subscribe for updates</label>
              <Box className='mx_cntrl'>
                <Row>
                  <Col md={12} className='mb-2'>
                    <InputGroup className='csmcntrlgrp csmcntrlgrp_inpt'>
                      {/* <FormControl
                        placeholder='Email'
                        aria-label='Email'
                        aria-describedby='basic-addon2'
                        {...formik.getFieldProps('email')}
                      /> */}
                      <TextField
                        id='outlined-basic'
                        label='E-Mail'
                        variant='outlined'
                        {...formik.getFieldProps('email')}
                      />
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        className='def_btn'
                        onClick={() => formik.handleSubmit()}
                      >
                        {loading ? (
                          <CircularProgress
                            color={'primary'}
                            className={classes.circularProgress}
                            size={20}
                          />
                        ) : (
                          'Subscribe'
                        )}
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={6} className='mb-2'>
                    {/* <InputGroup className='csmcntrlgrp'>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                      
                    </InputGroup> */}
                    <InputGroup className='csmcntrlgrp csmcntrlgrp_dtpckr'>
                      <TextField
                        id='date'
                        label='Birthday'
                        type='date'
                        defaultValue='2017-05-24'
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={6} className='mb-2'>
                    <FormControl fullWidth className='csmcntrlgrp2'>
                      <InputLabel id='demo-simple-select-label'>
                        Country
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={country}
                        label='Age'
                        onChange={changeHandler}
                      >
                        {options.map((country) => (
                          <MenuItem value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <InputGroup className="csmcntrlgrp2">
                      <Select 
                      styles={customStyles}
                      options={options} 
                      value={country} 
                      onChange={changeHandler}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </Box>

              <Box
                component='img'
                src='/static/img/catgirl_img.png'
                className='cat_img'
              />
            </Box>
          </Col>
          <Col md={12} className='hmsc02'>
            <Box className='mx_1290'>
              <Box
                component='img'
                src='/static/img/coming_soon_img.png'
                className='img-fluid'
              />
            </Box>
          </Col>
          <Col md={12} className='hmsc03'>
            <Box className='mx_1570'>
              <Box
                component='img'
                src='/static/img/nikotoshi_ic.png'
                className='img-fluid'
              />
              <Typography component='p'>
                Enjoy the beauty, intrigue, and playfulness of our neko anime
                NFTs. We love neko anime and we know you do too! Each Nekotoshi
                NFT is created with passion and love for neko anime.
              </Typography>
            </Box>
          </Col>
          <Col md={12} className='hmsc04'>
            <Box className='mx_1570'>
              <Typography component='p'>
                Every Nekotoshi NFT will be absolutely unique, providing an
                instant collectible that can be traded with friends, sold to
                collectors, and used in our upcoming dress-up and social games.
              </Typography>
            </Box>
          </Col>
          <Col md={12} className='hmsc05'>
            <Box className='hmsc05_bx'>
              <Box>
                <Box
                  component='img'
                  src='/static/img/nikotoshi_ic.png'
                  className='img-fluid'
                />
                <Typography component='h5'>
                  Subscribe for news and updates
                </Typography>
                <InputGroup className='csmcntrlgrp csmcntrlgrp_v2'>
                  {/* <FormControl
                    placeholder='Email'
                    aria-label='Email'
                    aria-describedby='basic-addon2'
                    {...formik.getFieldProps('email')}
                  /> */}
                  {/* <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    className='def_btn'
                    onClick={() => formik.handleSubmit()}
                  >
                    Subscribe
                  </Button> */}
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    className='def_btn'
                    onClick={scrollToTop}
                  >
                    Subscribe
                  </Button>
                </InputGroup>
                <Typography component='p'>
                  Subscribe to stay up to date with the latest news.
                  <span>Neko are coming soon! </span>
                </Typography>
              </Box>
            </Box>
            <Box
              component='img'
              src='/static/img/ftr_img.png'
              className='ftr_img'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withStyles(styles)(Home);

import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './components/Home';
import Header from './elements/Header';
import './styles/App.scss';
import '../node_modules/react-datepicker/dist/react-datepicker.css'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          
        </Switch>
        
      </div>
    </Router>
  );
}

export default App;
